import React from 'react';
import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const Features = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'services' });

  const fs: FeatureProps[] = t('features', { returnObjects: true });

  return (
    <Box
      py={'var(--chakra-space-20)'}
      id="features"
      bgColor={'var(--chakra-colors-gray-100)'}
      maxW={'full'}
    >
      <Stack spacing={12}>
        <Container
          display={'flex'}
          flexDirection={'column'}
          textAlign={'left'}
          maxW={'6xl'}
          paddingLeft={{ base: 'auto', md: 0 }}
        >
          <Heading
            fontSize={{ base: '4xl', sm: '4xl', lg: '5xl' }}
            fontWeight={'100'}
            width={'max-content'}
            _after={{
              content: "''",
              width: 'full',
              height: '20%',
              position: 'absolute',
              bottom: 1,
              left: 0,
              bg: 'var(--primary)',
              zIndex: -1,
              opacity: 0.2,
            }}
          >
            <b>{t('title')}</b> {t('title2')}
          </Heading>
        </Container>

        <Container maxW={{ base: 'full', md: '6xl' }}>
          <Flex
            display={{ base: 'flex', md: 'none' }}
            gap={6}
            justify="center"
            direction={{ base: 'column', md: 'row' }}
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
          >
            {fs.map((f, i) => (
              <Feature
                key={i}
                title={f.title}
                description={f.description}
                image={f.image}
              />
            ))}
          </Flex>
          <Grid
            display={{ base: 'none', md: 'grid' }}
            templateColumns={'repeat(3, 1fr)'}
            gap={6}
          >
            {fs.map((f, i) => (
              <Feature
                key={i}
                title={f.title}
                description={f.description}
                image={f.image}
              />
            ))}
          </Grid>
        </Container>
      </Stack>
    </Box>
  );
};

interface FeatureProps {
  title: string;
  description: string;
  image: string;
}

const Feature = ({ title, description, image }: FeatureProps) => {
  return (
    <Container maxW={{ base: 'full', md: 'xs' }}>
      <Stack spacing={2}>
        <Image mx={'auto'} h={'150px'} w={'150px'} pb={3} src={image} />

        <Heading size="sm">{title}</Heading>

        <Text py="2" size={'sm'}>
          {description}
        </Text>
      </Stack>
    </Container>
  );
};
