import React from 'react';
import { Container, Divider, Image, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const Technology = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'tech' });
  return (
    <Container maxW={{ base: 'xl', md: '5xl' }} mx={'auto'}>
      <Text
        textAlign={'center'}
        fontSize={{ base: 'xl', sm: '2xl', lg: '3xl' }}
        fontWeight={'100'}
        _before={{
          content: "''",
          width: '38%',
          height: '50%',
          borderBottom: '1px solid var(--chakra-colors-gray-200)',
          position: 'absolute',
          left: '0',
          opacity: '0.6',
        }}
        _after={{
          content: "''",
          width: '38%',
          height: '50%',
          borderBottom: '1px solid var(--chakra-colors-gray-200)',
          position: 'absolute',
          right: '0',
          opacity: '0.6',
        }}
      >
        <b>{t('title')}</b> {t('title2')}
      </Text>
      <Stack
        direction={{ base: 'row', md: 'row' }}
        padding={5}
        pt={1}
        alignItems={'center'}
        justifyContent={'center'}
        flexWrap={'wrap'}
      >
        <WrapImage src="/assets/tech/node.svg" alt="Node" />
        <WrapImage src="/assets/tech/golang.svg" alt="Go" />
        <WrapImage src="/assets/tech/elixir.svg" alt="Elixir" />
        <WrapImage src="/assets/tech/react.svg" alt="React" />
        <WrapImage src="/assets/tech/vue.svg" alt="Vue" />
        <WrapImage src="/assets/tech/ts.svg" alt="Typescript" />
        <WrapImage src="/assets/tech/kafka.svg" alt="Kafka" />
        <WrapImage src="/assets/tech/rabbitmq.svg" alt="RabbitMQ" />
        <WrapImage src="/assets/tech/aws.svg" alt="AWS" />
        <WrapImage src="/assets/tech/terraform.svg" alt="Terraform" />
        <WrapImage src="/assets/tech/k8s.svg" alt="Kubernetes" />
        <WrapImage src="/assets/tech/docker.svg" alt="Docker" />
        <WrapImage src="/assets/tech/graphql.svg" alt="GraphQL" />
        <WrapImage src="/assets/tech/mongo.svg" alt="MongoDB" />
        <WrapImage src="/assets/tech/psql.svg" alt="PostgreSQL" />
        <WrapImage src="/assets/tech/Android.svg" alt="Android" />
        <WrapImage src="/assets/tech/Apple.svg" alt="Apple" />
        <Text fontWeight={'100'}>{t('more')}</Text>
      </Stack>
      <Divider mb={{ base: 16, md: 28 }} />
    </Container>
  );
};

const WrapImage = ({ src, alt }: { src: string; alt: string }) => {
  return (
    <Container
      as="div"
      bgColor={'gray.200'}
      w={'auto'}
      p={2}
      centerContent
      borderRadius={'xl'}
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      gap={1}
    >
      <Image src={src} boxSize="30px" objectFit="fill" alt={alt} />
      <Text fontSize={'xs'}>{alt}</Text>
    </Container>
  );
};
