import React from 'react';
import {
  Box,
  Flex,
  Button,
  Stack,
  useBreakpointValue,
  Image,
  Text,
  IconButton,
  useDisclosure,
  Collapse,
} from '@chakra-ui/react';
import { LangSelect } from '../LangSelect/LangSelect';
import { useTranslation } from 'react-i18next';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';

export const Header = () => {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box boxShadow={'md'}>
      <Flex
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        padding={{ base: 6 }}
        paddingLeft={{ base: 10 }}
        paddingRight={{ base: 10 }}
        align={'center'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          justify={{ base: 'right', md: 'start' }}
          onClick={() => (window.location.href = '/')}
          _hover={{
            cursor: 'pointer',
          }}
        >
          <Stack direction="row" alignItems="center">
            <Image src="/logo.png" alt="Logo" boxSize="36px" />
            <Text fontFamily='"Poppins", Helvetica' fontWeight="800">
              CBSR
              <span style={{ fontFamily: 'Helvetica', fontWeight: '100' }}>
                .io
              </span>
            </Text>
          </Stack>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}
          display={{ base: 'none', md: 'flex' }}
        >
          <NavbarItem name={t('header.products')} href="#products" />
          <NavbarItem name={t('header.services')} href="#features" />
          <NavbarItem name={t('header.about')} href="#about" />
          <LangSelect />
          <Button
            display={{ base: 'inline-flex', md: 'inline-flex' }}
            textAlign={useBreakpointValue(
              { base: 'center', md: 'left' },
              { ssr: false },
            )}
            size={'lg'}
            padding="1rem"
            variant="outline"
            borderColor={'var(--primary)'}
            color={'var(--primary)'}
            _hover={{
              bg: 'var(--primary)',
              color: 'white',
            }}
            onClick={() => (window.location.href = '#contact')}
          >
            {t('header.cta')}
          </Button>
        </Stack>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Stack p={4} display={{ base: 'flex', md: 'none' }}>
          <NavbarItem name={t('header.products')} href="#products" />
          <NavbarItem name={t('header.services')} href="#features" />
          <NavbarItem name={t('header.about')} href="#about" />
          <LangSelect />
          <Button
            display={{ base: 'inline-flex', md: 'inline-flex' }}
            textAlign={useBreakpointValue(
              { base: 'center', md: 'left' },
              { ssr: false },
            )}
            size={'lg'}
            padding="1rem"
            variant="outline"
            borderColor={'var(--primary)'}
            color={'var(--primary)'}
            _hover={{
              bg: 'var(--primary)',
              color: 'white',
            }}
            onClick={() => (window.location.href = '#contact')}
          >
            {t('header.cta')}
          </Button>
        </Stack>
      </Collapse>
    </Box>
  );
};

const NavbarItem = ({ name, href }: { name: string; href?: string }) => {
  return (
    <Box
      as="a"
      textAlign={useBreakpointValue(
        { base: 'left', md: 'left' },
        { ssr: false },
      )}
      margin={'auto'}
      p={2}
      fontWeight={500}
      color={'var(--primary)'}
      fontSize={'lg'}
      href={href}
      _hover={{
        _after: {
          content: '""',
          display: 'block',
          height: '2px',
          width: '100%',
          backgroundColor: 'var(--primary)',
        },
      }}
    >
      {name}
    </Box>
  );
};
