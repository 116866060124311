import { Box, Text, Flex, useColorModeValue, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Logo = () => {
  return <Image src="/logo.png" alt="Logo" boxSize="36px" />;
};

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      boxShadow={'0px -1px 25px 0px rgba(0,0,0,0.1)'}
    >
      {/* 
        <Container as={Stack} maxW={'6xl'}>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={8}>
            <Stack align={'flex-start'}>
              <ListHeader>Product</ListHeader>
              <Box as="a" href={'#'}>
                Overview
              </Box>
              <Stack direction={'row'} align={'center'} spacing={2}>
                <Box as="a" href={'#'}>
                  Features
                </Box>
                <Tag
                  size={'sm'}
                  bg={useColorModeValue('green.300', 'green.800')}
                  ml={2}
                  color={'white'}
                >
                  New
                </Tag>
              </Stack>
              <Box as="a" href={'#'}>
                Tutorials
              </Box>
              <Box as="a" href={'#'}>
                Pricing
              </Box>
              <Box as="a" href={'#'}>
                Releases
              </Box>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Company</ListHeader>
              <Box as="a" href={'#'}>
                About Us
              </Box>
              <Box as="a" href={'#'}>
                Press
              </Box>
              <Box as="a" href={'#'}>
                Careers
              </Box>
              <Box as="a" href={'#'}>
                Contact Us
              </Box>
              <Box as="a" href={'#'}>
                Partners
              </Box>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Legal</ListHeader>
              <Box as="a" href={'#'}>
                Cookies Policy
              </Box>
              <Box as="a" href={'#'}>
                Privacy Policy
              </Box>
              <Box as="a" href={'#'}>
                Terms of Service
              </Box>
              <Box as="a" href={'#'}>
                Law Enforcement
              </Box>
              <Box as="a" href={'#'}>
                Status
              </Box>
            </Stack>
          </SimpleGrid>
        </Container>
      */}
      <Box pb={10}>
        <Flex
          align={'center'}
          position={'relative'}
          top={-18}
          _before={{
            content: '""',
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Logo />
        </Flex>
        <Text pt={6} fontSize={'sm'} textAlign={'center'}>
          {t('copy')}
        </Text>
      </Box>
    </Box>
  );
};
