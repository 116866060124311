import styled, { keyframes } from 'styled-components';

export interface BlobProps {
  borderRadius?: string;
}

const LoadingAnimation = keyframes`
0% {
    background-position: 0% 50%;
}
50% {
    background-position: 100% 50%;
}
100% {
    background-position: 0% 50%;
}
`;

export const Rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

export const Blob2 = styled.div<BlobProps>`
  position: relative;
  top: -120vh;
  z-index: 0;
  height: 120vh;
  background: linear-gradient(-81.04deg, #fef901, #f901fe);
  opacity: 0.5;
  border-radius: ${({ borderRadius }) => borderRadius || '0'};
`;

export const Blob = styled.div<BlobProps>`
  z-index: 2;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: -1;
    background: linear-gradient(
      -45deg,
      var(--chakra-colors-teal-200),
      var(--chakra-colors-purple-200),
      var(--chakra-colors-teal-200)
    );
    background-size: 400% 400%;
    top: 0;
    left: 0;
    animation: ${LoadingAnimation} 10s ease infinite;
  }
`;
