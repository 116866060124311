import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Container,
  Image,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface StatsCardProps {
  title: string;
  stat: string;
  icon: string;
}

function StatsCard({ title, stat, icon }: StatsCardProps) {
  return (
    <Container
      centerContent
      justifyContent={{ base: 'start', md: 'start' }}
      borderRadius={'xl'}
      p={5}
      bgColor={'white'}
      boxShadow={'lg'}
    >
      <Stack direction={'row'} alignItems={'center'} gap={'1rem'} w={'full'}>
        <Image src={icon} boxSize="100px" />
        <Stack direction={'column'} width={'full'}>
          <Text width={'full'} fontSize={'xl'} textAlign={'center'}>
            {title}
          </Text>
          <Text fontSize={'2xl'} fontWeight={'200'} textAlign={'center'}>
            {stat}
          </Text>
        </Stack>
      </Stack>
    </Container>
  );
}

interface Stats {
  title: string;
  value: string;
  icon: string;
}

export const About = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'about' });
  const stats: Stats[] = t('stats', { returnObjects: true });

  return (
    <Box py={'var(--chakra-space-20)'} id="about" maxW={'full'}>
      <Container maxW="6xl" paddingLeft={{ base: 'auto', md: 0 }}>
        <Stack spacing={12} mx={'auto'}>
          <Heading
            textAlign={'left'}
            fontSize={{ base: '4xl', sm: '4xl', lg: '5xl' }}
            fontWeight={100}
            width={'max-content'}
            _after={{
              content: "''",
              width: 'full',
              height: '20%',
              position: 'absolute',
              bottom: 1,
              left: 0,
              bg: 'var(--primary)',
              zIndex: -1,
              opacity: 0.2,
            }}
          >
            <b>{t('title')}</b> {t('title2')}
          </Heading>
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            spacing={{ base: 10, lg: 5 }}
            margin={{ base: 'auto', md: '0' }}
          >
            {stats.map((s, i) => (
              <StatsCard key={i} title={s.title} stat={s.value} icon={s.icon} />
            ))}
          </SimpleGrid>
        </Stack>
      </Container>
    </Box>
  );
};
