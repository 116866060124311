import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { CustomArrowProps } from 'react-slick';

export interface ArrowButtonProps {
  Icon: IconType;
  visible: ({
    currentSlide,
    slideCount,
    onClick,
  }: CustomArrowProps) => boolean | (() => boolean);
}

export const ArrowButton = ({
  Icon,
  visible,
  ...props
}: ArrowButtonProps & IconButtonProps) => {
  return ({ currentSlide, slideCount, onClick }: CustomArrowProps) => {
    if (!visible({ currentSlide, slideCount, onClick })) {
      return <></>;
    }

    return (
      <IconButton
        color={'var(--primary)'}
        borderRadius="full"
        position="absolute"
        {...props}
        transform={{ base: 'translate(0%, 0%)', md: 'translate(0%, 40%)' }}
        width={'var(--chakra-sizes-12)'}
        height={'var(--chakra-sizes-12)'}
        zIndex={3}
        onClick={onClick}
        boxShadow={'var(--chakra-shadows-base)'}
      >
        <Icon />
      </IconButton>
    );
  };
};
