import { Header } from './components/Header/Header';
import { ChakraProvider, Divider } from '@chakra-ui/react';
import { Hero } from './components/Hero/Hero';
import { Footer } from './components/Footer/Footer';
import { Features } from './components/Features/Features';
import { About } from './components/About/About';
import { Showcase } from './components/Products/Showcase';
import { Leads } from './components/Leads/Leads';
import { Blob } from './components/Hero/Blob';
import { Technology } from './components/Technology/Technology';

export const App = () => {
  return (
    <ChakraProvider>
      <Header />
      <Blob>
        <Hero />
      </Blob>
      <Showcase />
      <Features />
      <About />
      <Technology />
      <Leads />
      <Divider />
      <Footer />
    </ChakraProvider>
  );
};
