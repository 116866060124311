import React from 'react';
import { Box } from '@chakra-ui/react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import Slider, { CustomArrowProps, Settings } from 'react-slick';
import { Product } from './Product';
import { ArrowButton } from '../ArrowButton/ArrowButton';
import { useTranslation } from 'react-i18next';

const PArrowButton = ArrowButton({
  Icon: BiLeftArrowAlt,
  visible: ({ currentSlide }: CustomArrowProps) => currentSlide !== 0,
  top: { base: '100%', md: '50%' },
  left: { base: '10%', md: '0' },
  'aria-label': 'prev-arrow',
});

const NArrowButton = ArrowButton({
  Icon: BiRightArrowAlt,
  visible: ({ currentSlide, slideCount }: CustomArrowProps) =>
    currentSlide !== (slideCount || 1) - 1,
  top: { base: 'auto', md: '50%' },
  right: { base: '10%', md: '0' },
  'aria-label': 'next-arrow',
});

const settings: Settings = {
  dots: true,
  arrows: true,
  fade: true,
  //infinite: true,
  //autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <PArrowButton />,
  nextArrow: <NArrowButton />,
};

export const Showcase = () => {
  const [t] = useTranslation('translation', { keyPrefix: 'products' });
  return (
    <Box id="products" py={{ base: 12, md: 24 }}>
      <Box position={'relative'} maxW={'7xl'} margin={'auto'}>
        <Box maxW={'6xl'} margin={'auto'}>
          <Slider {...settings}>
            <Product
              name={t('chatbot.name')}
              subtitle={t('chatbot.subtitle')}
              description={t('chatbot.description')}
              description2={t('chatbot.description2')}
              image={t('chatbot.image')}
            />
            <Product
              name={t('e-commerce.name')}
              subtitle={t('e-commerce.subtitle')}
              description={t('e-commerce.description')}
              description2={t('e-commerce.description2')}
              image={t('e-commerce.image')}
            />
            <Product
              name={t('erp.name')}
              subtitle={t('erp.subtitle')}
              description={t('erp.description')}
              description2={t('erp.description2')}
              image={t('erp.image')}
            />
            <Product
              name={t('crm.name')}
              subtitle={t('crm.subtitle')}
              description={t('crm.description')}
              description2={t('crm.description2')}
              image={t('crm.image')}
            />
            <Product
              name={t('consulting.name')}
              subtitle={t('consulting.subtitle')}
              description={t('consulting.description')}
              description2={t('consulting.description2')}
              image={t('consulting.image')}
            />
            <Product
              name={t('other.name')}
              subtitle={t('other.subtitle')}
              description={t('other.description')}
              description2={t('other.description2')}
              image={t('other.image')}
            />
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};
