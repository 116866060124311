import { Heading, Text, Stack, Container } from '@chakra-ui/react';
import { Widget } from '@typeform/embed-react';
import { useTranslation } from 'react-i18next';
import { LanguagesEnum } from '../LangSelect/constants';

export const Leads = () => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'contact' });

  return (
    <Container
      id="contact"
      maxW={'full'}
      centerContent
      paddingBottom={'var(--chakra-space-24)'}
    >
      <Stack
        spacing={10}
        maxW={'6xl'}
        direction={{ base: 'column', md: 'row' }}
        h={{ base: 'auto', md: '70vh' }}
        p={'var(--chakra-space-16)'}
        borderRadius={'var(--chakra-radii-2xl)'}
        bgGradient="linear(-45deg, var(--primary), var(--primary-dark))"
        boxShadow={'var(--chakra-shadows-2xl)'}
      >
        <Stack gap={6}>
          <Heading
            fontSize={{ base: '2xl', sm: '4xl' }}
            fontWeight={'bold'}
            color={'white'}
          >
            {t('title')}
          </Heading>
          <Text color={'white'} fontSize={{ base: 'sm', sm: 'lg' }}>
            {t('subtitle')}
          </Text>
        </Stack>
        <Container w={'full'} h={{ base: '100px', md: 'full' }}>
          <Widget
            id={
              i18n.language === LanguagesEnum.Portuguese
                ? 'GuQL8a83'
                : 'X0Wy5NVO'
            }
            style={{ height: 'inherit', width: 'inherit' }}
          />
        </Container>
      </Stack>
    </Container>
  );
};
