import { Container, Stack, Flex, Heading, Text, Image } from '@chakra-ui/react';
import { Blob } from '../Blob/Blob';

export interface Props {
  name: string;
  subtitle: string;
  description: string;
  description2: string;
  image: string;
}

export const Product = ({
  name,
  subtitle,
  description,
  description2,
  image,
}: Props) => {
  return (
    <Container maxW={'5xl'} py={{ base: 0, md: 20 }}>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        direction={{ base: 'column', md: 'row' }}
        height={{ base: '100%', md: '50vh' }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 1 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '4xl', sm: '4xl', lg: '5xl' }}
          >
            <Text
              as={'span'}
              position={'relative'}
              _after={{
                content: "''",
                width: 'full',
                height: '20%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: 'var(--secondary-dark)',
                zIndex: -1,
                opacity: 0.2,
              }}
            >
              {name}
            </Text>
          </Heading>
          <Text
            color={'var(--primary)'}
            fontSize={'2xl'}
            mb={10}
            fontWeight={600}
          >
            {subtitle}
          </Text>
          <Text color={'gray.500'}>{description}</Text>
          <Text color={'gray.500'}>{description2}</Text>
        </Stack>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}
        >
          <Blob
            w={'110%'}
            h={'150%'}
            position={'absolute'}
            top={'-20%'}
            zIndex={-1}
            opacity={0.1}
            color={'var(--secondary-dark)'}
          />
          <Image
            alt={'Hero Image'}
            fit={'cover'}
            align={'center'}
            w={'100%'}
            h={'100%'}
            src={image}
          />
        </Flex>
      </Stack>
    </Container>
  );
};
