import { Box, Heading, Container, Text, Button, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const Hero = () => {
  const [t] = useTranslation();
  return (
    <>
      <Container maxW={'6xl'} py={'20vh'}>
        <Stack
          as={Box}
          textAlign={'center'}
          spacing={{ base: 8, md: 14 }}
          position={'relative'}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '5xl', sm: '5xl', md: '5rem' }}
            lineHeight={'110%'}
          >
            {t('hero.title')} <br />
            <Text as={'span'} color={'var(--primary)'}>
              {t('hero.title2')}
            </Text>
          </Heading>
          <Text color={'gray.500'}>
            {t('hero.subtitle')} <br />
            {t('hero.subtitle2')}
          </Text>

          <Stack
            direction={'column'}
            spacing={3}
            align={'center'}
            alignSelf={'center'}
            position={'relative'}
          >
            <Button
              bg={'var(--primary)'}
              rounded={'full'}
              px={6}
              color={'white'}
              _hover={{
                bg: 'transparent',
                color: 'var(--primary)',
                border: '1px solid var(--primary)',
              }}
              onClick={() => {
                window.location.href = '#contact';
              }}
            >
              {t('hero.cta')}
            </Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};
