import { Button, ButtonGroup } from '@chakra-ui/react';
import { LanguagesEnum } from './constants';
import { useTranslation } from 'react-i18next';

export const LangSelect = () => {
  const [_, i18n] = useTranslation();
  const changeLanguage = (lng: LanguagesEnum) => {
    i18n.changeLanguage(lng);
  };

  return (
    <ButtonGroup size="md" isAttached variant="outline" margin={'auto'}>
      {Object.values(LanguagesEnum).map((language, index) => {
        return (
          <Button
            key={index}
            borderColor={'var(--primary)'}
            backgroundColor={
              i18n.language === language ? 'var(--primary)' : 'transparent'
            }
            color={i18n.language === language ? 'white' : 'var(--primary)'}
            _hover={{
              bg: 'var(--primary)',
              color: 'white',
            }}
            onClick={() => changeLanguage(language)}
          >
            {language}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};
